<template>
	<div class="container">
		<bread-crums :nesting="['Dental Care', 'Orthodontics']"></bread-crums>
		<intro button-text="Contact Us" button-link="/contact" title="Orthodontics" :content="paragraphs"/>
		<div class="pgcontent">
			<div v-for="card in cards" :key="card.title" class="card">
				<h2>{{card.title}}</h2>
				<p>{{card.content}}</p>
			</div>
      <div v-for="section in sections" :key="section.title">
        <p>{{ section }}</p>
      </div>
			<div class="table">
				<price-table :procedure="Procedures['categories'][1]"></price-table>
			</div>
		</div>      
	</div>
</template>

<script>
import Intro from '../components/Intro.vue'
import PriceTable from '../components/PriceTable.vue'
import  Procedures  from "../assets/json/procedures.json";
import BreadCrums from '../components/BreadCrums.vue';
export default {
	name: 'GeneralDentisty',
	components: {
		Intro,
		PriceTable,
		BreadCrums
	},
	data(){
		return{
			Procedures,
			paragraphs: [
					"We offer treatment to straighten teeth that need mild movement. This mainly focuses on the front area of the teeth. We can carry out treatment using fixed clear brackets supported by an orthodontic wire or using removable transparent aligners. Both systems work slightly differently but can be effective in achieving your improved smile. "
			],
			cards:[
			],
      sections:[
        "We will need to carry out some planning and diagnostic stages prior to starting your treatment. This may involve having special type of X-ray taken to ensure there are no impacted teeth in your jaws, then using a 3D scanner to plan the possible movement of your teeth (when removable aligners are used). Treatment times can vary; it could from 5-6 months or up to a year to achieve your desired results. Each case is different and your dentist will advise you on the options to ensure a predictable outcome can be created from your treatment."
      ]
		}
	}
}
</script>

<style scoped>
	.pgcontent {
		background-color: #FFF;
		padding: 1.5rem;
	}
	h2{
		width: 95%;
		color: var(--primary)
	}
	@media screen and (min-width: 375px){
		.pgcontent{
			padding: 1.5rem 2.5rem;
		}
	}
	@media screen and (min-width: 768px){
		.pgcontent{
			padding: 1.5rem6rem;
		}	
	}
	@media screen and (min-width: 1024px) {
		
		.container{
			padding-top: 1rem;
		}
		.pgcontent{
			display: flex;
			flex-wrap: wrap;
			padding: 2rem var(--padding1);
			justify-content: center;
		}
		.card{
			max-width: 50%;			
		}
		p{
			width: 70%;
		}
		.table{
			width: 100%;
		}
		
	}
	@media screen and (min-width: 1440px) {		
		.pgcontent{
			padding: 2rem var(--padding2);
		}    
	}
	@media screen and (min-width: 1750px){
		.pgcontent{
			padding: 2rem 4rem;
		}    
	}
</style>